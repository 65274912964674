<template>
  <div class="box">
    <div class="u-design">
      <div class="edit-box">
        <div class="edit-shop-content">
          <section class="edit-shop-bottomnav">
            <h3 style="padding: 10px 20px; font-size: 18px; color: #666; border-bottom: 1px solid #eee; margin-bottom: 20px;">基础信息</h3>
            <el-form :model="content" label-width="100px" size="small">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="背景颜色：">
                    <el-color-picker v-model="content.bgcolor"></el-color-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="文字初始颜色：">
                    <el-color-picker v-model="content.nav_color"></el-color-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="文字选中颜色：">
                    <el-color-picker v-model="content.nav_hover_color"></el-color-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <h3 style="padding: 10px 20px; font-size: 18px; color: #666; border-bottom: 1px solid #eee; margin-bottom: 20px;">菜单设置</h3>
            <draggable v-model="content.data" :options="bottomNavOptions">
            <div class="edit-item" v-for="(item, index) in content.data" :key="item.id">
              <el-form :model="item" label-width="140px" style="position: relative;" size="small">
                <span style="position: absolute; right: 10px; top: 0px; cursor: pointer; z-index: 1">
                  <el-popconfirm
                          title="您确认删除吗？"
                          @confirm="deleteNav(index)">
                    <el-button type="text" slot="reference">
                      <i class="el-icon-delete" style="font-size: 18px;" />
                    </el-button>
                  </el-popconfirm>
                </span>
                <span style="position: absolute; left: 0px; top: -10px; cursor: pointer; z-index: 1" title="长按拖拽">
                  <el-button type="text" slot="reference">
                      <i class="el-icon-rank" style="font-size: 24px;" />
                    </el-button>
                </span>
                <el-row style="height: 100px; margin-top: 10px; clear: both;">
                  <el-col :span="5">
                    <SelectImgs :selectNum="1" btnTitle="未选中图标" btnClass="w50" :selectData="[item.nav_icon]" :idx="index" :selectFunc="setImgUrl"></SelectImgs>
                  </el-col>
                  <el-col :span="5">
                    <SelectImgs :selectNum="1" btnTitle="选中图标" btnClass="w50" :selectData="[item.nav_hover_icon]" :idx="index" :selectFunc="setActImgUrl"></SelectImgs>
                  </el-col>
                  <el-col :span="14">
                    <div>
                      <el-row :gutter="10">
                        <el-col :span="4" style="line-height: 35px">
                          按钮名称
                        </el-col>
                        <el-col :span="16">
                          <el-input v-model="item.nav_name" placeholder="请输入最多可输入5个字" maxlength="5" size="small"></el-input>
                        </el-col>
                      </el-row>
                    </div>
                    <div class="mg-tp-20">
                      <el-row :gutter="10">
                        <el-col :span="4" style="line-height: 35px">
                          链接地址
                        </el-col>
                        <el-col :span="16">
                          <select-link :setData="item.nav_link" :index="index" :setFunc="setNavFunc">
                            <template slot="button">
                              <el-input :value="item.nav_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                            </template>
                          </select-link>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            </draggable>
            <div style="overflow:hidden; margin-top: 20px; margin-left: 10px;">
              <el-button type="primary" size="small" @click="addNav" class="fl" :disabled="content.data.length > 4">添加菜单</el-button>
              <p class="edit-tips" style="margin-top: 7px;">最多可添加5个菜单项</p>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="saveBox">
      <el-button type="primary" size="small" @click="savePage">保存数据</el-button>
    </div>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import selectLink from '../module/select.link.vue'
import draggable from 'vuedraggable';
export default {
  components: {
    selectLink,
    draggable,
    SelectImgs
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      isSelectId: 0,
      designPreviewHeight: 510,
      phoneScreenHeight: 510,
      content: {
        bgcolor: '#fff',
        nav_color: '#333', // 初始颜色
        nav_hover_color: '#333', // 选中颜色
        data: []
      },
      defaultContent: {
        bgcolor: '#fff',
        nav_color: '#333', // 初始颜色
        nav_hover_color: '#333', // 选中颜色
        data: [
          {
            nav_name: '首页', // 导航文字
            nav_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_01.png',
            nav_hover_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_01_act.png',
            nav_link: { // 导航链接
              type: '6',
              name: '店铺首页',
              id: ''
            }
          },
          {
            nav_name: '订单', // 导航文字
            nav_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_02.png',
            nav_hover_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_02_act.png',
            nav_link: { // 导航链接
              type: '7',
              name: '订单列表',
              id: ''
            }
          },
          {
            nav_name: '购物车', // 导航文字
            nav_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_03.png',
            nav_hover_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_03_act.png',
            nav_link: { // 导航链接
              type: '8',
              name: '购物车',
              id: ''
            }
          },
          {
            nav_name: '我的', // 导航文字
            nav_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_04.png',
            nav_hover_icon: this.config.SOURCE_DOMAIN + '/images/bottom/ico_04_act.png',
            nav_link: { // 导航链接
              type: '9',
              name: '会员中心',
              id: ''
            }
          }
        ]
      },
      bottomNavOptions: {
        group: {
          name: 'bottomNav',
          pull: true,
          put: true
        },
        sort: true,
        animation: 100
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setImgUrl(item, idx) {
      this.content.data[idx].nav_icon = item.pic
    },
    setActImgUrl(item, idx) {
      this.content.data[idx].nav_hover_icon = item.pic
    },
    setNavFunc(item, idx) {
      this.content.data[idx].nav_link = item
    },
    addNav() {
      this.content.data.push({
        nav_name: '菜单', // 导航文字
        nav_color: '#333', // 初始颜色
        nav_hover_color: '#333', // 选中颜色
        nav_icon: '',
        nav_hover_icon: '',
        nav_link: { // 导航链接
          type: '',
          name: '',
          id: ''
        }
      })
    },
    deleteNav(index) {
      this.content.data.splice(index,1)
    },
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.shop.designNavInfo({type:1}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          if(res.data) {
            that.content = res.data
          } else {
            that.content = that.defaultContent
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    savePage() {
      var that = this
      that.showLoading()
      var param = {
        type: 1,
        content: this.content
      }
      this.$api.shop.designNavEdit(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
  .u-design{min-height:578px; display: flex}
  .edit-box{margin-bottom: 70px;width: 750px}
  .edit-head h3{display:inline-block;margin:0 15px 0 0;font-weight:400;font-size:1pc;line-height:20px}
  .edit-shop-bottomnav {
    background-color: #fff;
  }
  .edit-shop-bottomnav {
    background-color: #fff;
  }
  .edit-shop-bottomnav .edit-tips{
    float:left;margin-left: 20px; margin-top: 1px;color: #999;
  }
  .edit-item{
    background-color: #f8f8f9;
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;
  }
</style>
